import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Auth from '@/api/auth-api'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	status: '',
    token: localStorage.getItem('token') || '',
    erro:''
  },
  getters : {
	isLoggedIn: state => !!state.token,
	authStatus: state => state.status,
	authToken: state => state.token,
  },
  mutations: {
  	auth_request(state){
	    state.status = 'loading'
	},
	auth_success(state){
	    state.status = 'success'	    
	},
	auth_token(state,token){
	    state.token = token
	},
    auth_error(state){
    	state.status = 'error'
    },
    erro(state,erro){
    	state.erro = erro
    },
    logout(state){
    	state.token = ''
    },
  },
  actions: {
  	login({commit}, user){
	    return new Promise((resolve, reject) => {
	      commit('auth_request')

	      Auth.login(user.one_login,user.one_senha,user.one_token_dev)
	      .then(resp => {	      	
	      	
	        localStorage.setItem('token',resp.data.token)

	        commit('auth_success')
	        commit('auth_token', resp.data.token)	
	        
	        resolve(resp)
	      })
	      .catch(err => {
	      	
	      	commit('erro',err.response)
	      	
	        commit('auth_error')
	        localStorage.removeItem('token')
	        reject(err)
	      })
	    })
	},
	logout({commit}){
	  return new Promise((resolve, reject) => {
	    commit('logout')
	    localStorage.removeItem('token')
	    delete axios.defaults.headers.common['Authorization']
	    resolve()
	  })
	}
  }
})

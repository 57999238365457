<template>
  	<div class="home">
	  	<h1>Eae men {{nome}}</h1>
	  	<a href="#" @click="myFunction"> Ir para AKBANK</a>
	</div>
</template>

<script>
import HomeApi from '@/api/home-api'
import $ from 'jquery'

export default {
  name: 'home',
  data(){
  	return{
  		nome:''
  	}
  },
  methods:{
  	myFunction: function () {   
		window.open("https://bank.akmosplay.com.br/#/login?token="+this.$store.state.token, '_blank');        
    }
  },
  created(){
  	var self = this
  	HomeApi.saudacao(this.$store.state.token).then((response)=>{ 	
			let data = response.data.data 				
			self.nome = data.login
			console.log(data.login,data.id_cadastro)
		},
	).catch(function (error) {
    
	    if(error.response.status===401){
	    	self.$store.dispatch('logout').then(() => {
				self.$router.push('/login')
			})
	    }else{
	    	console.log(error)
	    }
  	})
  },
  mounted(){
  	alert(localStorage.getItem('token'))
  },
}
</script>
<style type="text/css">
	.novasmsg{
		color:#d30c0c;
		font-weight: 700;
	}
	.suporte_akmos{
		position: absolute;
    	width: 100%;
    	bottom: 0;
	}
</style>




